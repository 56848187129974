<template>
  <div class="container">
    <PageTitle>TOEFL</PageTitle>
    <hr />
    <el-select v-model="tpo" style="width:100%" @change="setUrl">
      <el-option
        v-for="item in 54"
        :key="item"
        :label="'TPO ' + item"
        :value="item + ''"
      >
      </el-option>
    </el-select>
    <hr />
    <el-tabs v-model="activeVersion" type="card">
      <el-tab-pane label="Old" name="old">
        <ViewItems :contents="contentsOld"></ViewItems>
      </el-tab-pane>
      <el-tab-pane label="New" name="new">
        <ViewItems :contents="contents"></ViewItems>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import PageTitle from "@/components/PageTitle.vue";
import TOEFL from "@/apis/toefl";
import ViewItems from "@/views/toefl/toeflTest/ViewAllComponents.vue";

export default {
  metaInfo() {
    return {
      title: "Writing Content - " + this.CompanyName
    };
  },

  components: {
    PageTitle,
    ViewItems
  },

  mixins: [],

  props: [],
  data() {
    return {
      tpo: "1",
      contents: [],
      contentsOld: [],
      activeVersion: "old"
    };
  },
  computed: {},
  watch: {},

  mounted() {
    if (this.$route.query.tpo) {
      this.tpo = this.$route.query.tpo;
    }
    this.getContent();
  },

  methods: {
    async getContent() {
      let res = await TOEFL.getContent(this.tpo);
      this.contents = res.toefl_chapters.new;
      this.contentsOld = res.toefl_chapters.old;
    },
    setUrl(val) {
      this.$router.replace({
        path: "/AllContent?tpo=" + val
      });
    }
  }
};
</script>

<style scoped>
.container {
  padding-top: 40px;
}
</style>
